<template>
  <div>
    <div class="timetable-title">{{ handleDate(data.scheduleTime) }}</div>
    <div class="timetable-main">
        <div class="timetable-div" v-for="(item,index) in data.child" :key="index" @mouseenter="timetableEnter(item.id)" @mouseleave="timetableLeave(item.id)">
            <img :src="crumbClose" alt="" class="img-icon" :class="'img-icon' + item.id" @click="delTable(item)">
            <time-table-detail :childData="item"></time-table-detail>
        </div>
    </div>
  </div>
</template>

<script>
import TimeTableDetail from "./TimeTableDetail.vue"
export default {
    components:{
        TimeTableDetail
    },
    props:{
        data:Object
    },
    computed:{
        crumbClose () {
            return require('@/assets/images/crumb-close.png');
        }
    },
    data(){
        return{}
    },
    mounted(){
        console.log('课表的data',this.data)
    },
    methods:{
        /**
         * @Description:处理日期
         * @Author: mrz
         * @Date: 2024-08-26 10:25:53
         */
         handleDate(val){
            const days = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
            const date = new Date(val)
            return days[date.getDay()]  + ' (' + val + ')'
         },
        /**
         * @Description: 鼠标移入事件
         * @Author: mrz
         * @Date: 2024-08-25 10:43:22
         */
         timetableEnter(id){
            const currentEl = document.getElementById(`detail_${id}`)
            currentEl.style.backgroundColor = '#DFEAFF'
         },
        /**
         * @Description: 鼠标移出事件
         * @Author: mrz
         * @Date: 2024-08-25 11:15:30
         */
         timetableLeave(id){
            const currentEl = document.getElementById(`detail_${id}`)
			currentEl.style.backgroundColor = '#F4F4F5'
         },
        /**
         * @Description: 点击删除
         * @Author: mrz
         * @Date: 2024-08-31 18:35:48
         */
         delTable(val){
            console.log('val',val.scheduleTime)
            val.checkState = false
            this.$emit('delItem',val)
         }
    }
}
</script>

<style lang="scss" scoped>
.timetable-title{
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 14px;
    color: #2B2F33;
    margin: 14px 0;
}
.timetable-main{
    display: flex;
    flex-wrap: wrap;
    gap:10px;
    .timetable-div{
        position: relative;
        &:hover {
            .img-icon{
                display: block;
            }
        }
        .img-icon{
            position: absolute;
            left: 134px;
            top: -6px;
            display: none;
        }
    }
}

</style>
