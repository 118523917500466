<template>
  <div :class="['time-table-detail-content',{ currentItem: childData.sel }]" :id="'detail_' + childData.id">
    <div v-if="childData.type == '2'" class="detail">
        <div class="time">{{ childData._uuName.substring(0,3) }} {{ (childData._time) }}</div>
        <div class="grade">{{ childData.className }}</div>
        <div class="teacher">
            <span class="teacher-name">{{ childData.teacherName }}</span>
            <span class="subject">({{ childData.subjectName }})</span>
        </div>
    </div>
    <div v-else-if="childData.type == '3'" class="detail">
        <div class="time">{{ childData.label.substring(0,3) }} {{ (childData.time) }}</div>
        <div class="grade">{{ childData.className }}</div>
        <div class="teacher">
            <span class="teacher-name">{{ childData.teacherName }}</span>
            <span class="subject">({{ childData.subjectName }})</span>
        </div>
    </div>

  </div>
</template>

<script>
export default {
    props:{
        childData:Object
    },
    mounted(){
        console.log('timetabledetail-----------',this.childData)
    }
}
</script>

<style lang="scss" scoped>
.detail{
    width: 140px;
    height: 80px;
    background: #F4F4F5;
    border-radius: 4px;
    border: 1px solid #E9E9EB;
    padding: 11px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .time,.grade{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
    }
    .teacher{
        .teacher-name{
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 14px;
            color: #333333;
            margin-right: 8px;
        }
        .subject{
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #333333;
        }
    }
}
.currentItem{
    background: #E8F0FF !important;
    border: 1px solid #E9E9EB;
}
</style>
